@use '@angular/material' as mat;
@mixin dt-mt-topmenu-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: mat.get-color-from-palette($theme, background);
  $foreground: mat.get-color-from-palette($theme, foreground);

  .dt-mt-topmenu {
    background: mat.get-color-from-palette($background, app-bar);
    color: mat.get-color-from-palette($foreground, text);

    .mat-button {
      &.active {
        background-color: mat.get-color-from-palette($background, focused-button);
      }
    }
  }

  .mat-menu-item.active {
    color: mat.get-color-from-palette($accent);
  }
}
