.dt-mt-row {
  margin-right: -1 * calc($gutter / 2);
  margin-left: -1 * calc($gutter / 2);
}

.dt-mt-col {
  padding-right: calc($gutter / 2);
  padding-left: calc($gutter / 2);
}

.badge {
  @include badge();
}

.scrollbar-thin {
  @include scrollbar-thin();
}

.scrollbar-none {
  @include scrollbar-none();
}
