// Example for custom theming
@use '@angular/material' as mat;
@mixin dt-mt-table-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $background: map-get($theme, "background");
  $foreground: map-get($theme, "foreground");
  $row-hover-bg: mat.get-color-from-palette($primary, 50);
  $row-odd-bg: mat.get-color-from-palette(mat.$gray-palette, 100);
  $row-hover-bg-dark: mat.get-color-from-palette(mat.$blue-gray-palette, 900);
  $row-odd-bg-dark: lighten(mat.get-color-from-palette(mat.$gray-palette, 900), 10%);

  $row-alarm-hover-bg-dark: mat.get-color-from-palette(mat.$red-palette, 900);
  $row-alarm-hover-bg: mat.get-color-from-palette(mat.$red-palette, 50);

  .mat-row:hover {
    background-color: if($is-dark-theme, $row-hover-bg-dark, $row-hover-bg);
  }

  .mat-row:selected {
    background-color: if($is-dark-theme, $row-hover-bg-dark, $row-hover-bg);
  }

  .dt-mt-selected-row {
    background-color: mat.get-color-from-palette($accent, 200);
  }

  .mat-row {
    &.selected {
      background: mat.get-color-from-palette($primary);
    }
  }

  .mat-row:nth-child(odd) {
    background-color: if($is-dark-theme, $row-odd-bg-dark, $row-odd-bg);
  }

  .mat-row-alarm:hover {
    background-color: if(
      $is-dark-theme,
      $row-alarm-hover-bg-dark,
      $row-alarm-hover-bg
    );
  }

  .mat-row-alarm {
    &.selected {
      background: if(
        $is-dark-theme,
        $row-alarm-hover-bg-dark,
        $row-alarm-hover-bg
      );
    }
  }
}

// @mixin dt-mt-table-theme($theme) {
//   $is-dark-theme: map-get($theme, is-dark);
//   $background: map-get($theme, "background");
//   $foreground: map-get($theme, "foreground");

//   $row-hover-bg: mat-color($mat-indigo, 50);
//   $row-odd-bg: mat-color($mat-gray, 100);

//   $row-hover-bg-dark: mat-color($mat-blue-gray, 900);
//   $row-odd-bg-dark: lighten(mat-color($mat-gray, 900), 10%);

//   mtx-grid.mtx-grid {
//     .mat-table {
//       &.mat-table-striped {
//         .mat-row-odd {
//           background-color: if($is-dark-theme, $row-odd-bg-dark, $row-odd-bg);
//         }
//       }

//       &.mat-table-hover {
//         .mat-row {
//           &:hover {
//             background-color: if(
//               $is-dark-theme,
//               $row-hover-bg-dark,
//               $row-hover-bg
//             );
//           }
//         }
//       }

//       .mat-row {
//         &.selected {
//           background-color: if(
//             $is-dark-theme,
//             $row-hover-bg-dark,
//             $row-hover-bg
//           );
//         }
//       }
//     }
//   }
// }
