@use '@angular/material' as mat;
@import '../style/variables';

@mixin dt-mt-sidemenu-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .dt-mt-sidemenu {
    > .dt-mt-sidemenu-item {
      > a {
        color: mat.get-color-from-palette($foreground, text);

        &:hover {
          background: mat.get-color-from-palette($background, hover);
        }
      }

      &.active {
        > a {
          background-color: mat.get-color-from-palette($background, focused-button);
          // background-color: mat-color($accent);
          // color: mat-color($accent);

          &:hover {
            background: mat.get-color-from-palette($background, hover);
          }
        }
      }

      &.open {
        background: mat.get-color-from-palette($background, hover);
      }
    }

    &.dt-mt-sidemenu-sub {
      > .dt-mt-sidemenu-item {
        &.active {
          > a {
            background-color: transparent;

            &:hover {
              background: mat.get-color-from-palette($background, hover);
            }
          }
        }

        &.open {
          background: transparent;
        }
      }
    }
  }
}
