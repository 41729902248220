@use '@angular/material' as mat;
@mixin dt-mt-header-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .dt-mt-toolbar-button {
    &.mat-icon-button {
      &:hover {
        background-color: mat.get-color-from-palette($background, hover);
      }
    }
  }

  .dt-mt-branding-name {
    color: mat.get-color-from-palette($foreground, text);
  }
}
