@use '@angular/material' as mat;
@import '../style/variables';

@mixin dt-mt-sidebar-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .dt-mt-sidebar-header {
    color: mat.get-color-from-palette($foreground, text);
    background: mat.get-color-from-palette($background, dialog);
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }

  .dt-mt-user-panel {
    border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
  }
}
