@use '@angular/material' as mat;

@mixin dt-mt-error-code-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .dt-mt-error-code {
    color: mat.get-color-from-palette($foreground, text);
  }
}
